<template>
  <div>
      <AddRoleForm></AddRoleForm>
  </div>
</template>

<script>
import AddRoleForm from '../../../components/Dashboard/Roles/AddRoleForm'
export default {
  components: { AddRoleForm },

}
</script>

<style>

</style>